import { all } from 'redux-saga/effects';
// Providers
import AuthProvider from 'providers/Auth';
// Sagas
import authenticationSaga from './authentication';

const Auth = new AuthProvider();

export default function* rootSaga() {
  yield all([authenticationSaga(Auth)]);
}
