import React, { useEffect } from 'react';
import OktaSignIn from '@okta/okta-signin-widget';
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';
import oktaConfig from 'constants/okta';

const {
  baseUrl,
  clientId,
  redirectUri,
  issuer,
  pkce,
  scopes,
} = oktaConfig.oidc;

const signin = new OktaSignIn({
  baseUrl,
  clientId,
  redirectUri,
  i18n: {
    en: {
      'primaryauth.title': 'Sign in to Castellum Ventures',
    },
  },
  authParams: {
    pkce,
    issuer,
    display: 'page',
    responseMode: 'pkce' ? 'query' : 'fragment',
    scopes,
  },
});

const LoginWidget = () => {
  useEffect(() => {
    signin.renderEl({ el: '#osw-container' });
    return () => signin.remove();
  });
  return <div id="osw-container" />;
};

export default LoginWidget;
