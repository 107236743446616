const STORAGE_KEY = 'redirect';
const fallbackRedirectURI = '/';

export const saveRedirectURI = () => {
  const currentPath = window.location.pathname;
  window.localStorage.setItem(STORAGE_KEY, currentPath);
};

export const retrieveRedirectURI = () => {
  const redirectURI = window.localStorage.getItem(STORAGE_KEY);
  window.localStorage.removeItem(STORAGE_KEY);
  return redirectURI || fallbackRedirectURI;
};
