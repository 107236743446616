import React, { useEffect } from 'react';
// Hooks
import { useDispatch, useSelector } from 'react-redux';
// Components
import { Redirect } from 'react-router-dom';
import Loader from 'components/Loader';
// Selectors
import { isAuthenticatedSelector } from 'selectors/authentication';
// Actions
import { receiveStateAndNonce } from 'actions/authentication';
// Utilities
import { retrieveRedirectURI } from 'utilities/redirectURI';

const ImplicitCallback = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(isAuthenticatedSelector);

  const search = window.location.search.split('?')[1] || '';
  let params = {};
  search.split('&').forEach(str => {
    const [k, v] = str.split('=');
    params = { ...params, [k]: v };
  });

  useEffect(() => {
    if (!isAuthenticated) dispatch(receiveStateAndNonce(params));
  });
  if (!isAuthenticated) return <Loader />;
  return <Redirect to={retrieveRedirectURI()} />;
};

export default ImplicitCallback;
