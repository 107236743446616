import OktaAuth from '@okta/okta-auth-js';
import oktaConfig from 'constants/okta';

export default class Auth {
  constructor() {
    this.auth = new OktaAuth(oktaConfig.oidc);
  }
  sessionExists = async () => {
    const sessionExists = await this.auth.session.exists();
    if (!sessionExists) return sessionExists;
    const tokens = this.retreiveStoredTokens();
    return tokens;
  };

  getTokens = authData =>
    this.auth.token
      .getWithoutPrompt({
        scopes: oktaConfig.oidc.scopes,
        ...authData,
      })
      .then(({ tokens }) => tokens);

  retreiveStoredTokens = async () => {
    const idTokenPromise = this.auth.tokenManager
      .get('idToken')
      .then(value => ({ idToken: value }));
    const accessTokenPromise = this.auth.tokenManager
      .get('accessToken')
      .then(value => ({ accessToken: value }));
    const tokenArray = await Promise.all([
      idTokenPromise,
      accessTokenPromise,
    ]);
    let tokens = {};
    tokenArray.forEach(token => (tokens = { ...token }));
    return tokens;
  };

  storeToken = (k, v) => this.auth.tokenManager.add(k, v);

  storeTokens = tokens => {
    const tokenArray = Object.values(tokens);
    tokenArray.forEach(token => {
      if (token.accessToken) this.storeToken('accessToken', token);
      if (token.idToken) this.storeToken('idToken', token);
    });
  };
}
