export const LOGIN = 'LOGIN';
export const RECEIVE_STATE_AND_NONCE = 'RECEIVE_STATE_AND_NONCE';
export const RECEIVE_TOKENS = 'RECEIVE_TOKENS';
export const SESSION_INACTIVE = 'SESSION_INACTIVE';
export const RECOVER_SESSION = 'RECOVER_SESSION';

export const sessionInactive = () => ({
  type: SESSION_INACTIVE,
});

export const receiveStateAndNonce = ({ state, code }) => ({
  type: RECEIVE_STATE_AND_NONCE,
  state,
  nonce: code,
});

export const receiveTokens = tokens => ({
  type: RECEIVE_TOKENS,
  ...tokens,
});

export const recoverSession = () => ({
  type: RECOVER_SESSION,
});
