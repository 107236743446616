import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import LoginContainer from 'containers/Login';
import Home from 'containers/Home';
import ImplicitCallback from 'components/ImplicitCallback';

const App = () => (
  <Router>
    <Switch>
      <Route path="/login" component={LoginContainer} />
      <Route path="/implicit/callback" component={ImplicitCallback} />
      <Route component={Home} />
    </Switch>
  </Router>
);

export default App;
