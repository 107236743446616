import { RECEIVE_TOKENS } from 'actions/authentication';

const initialState = {
  authenticated: null,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case 'SESSION_INACTIVE':
      return { ...state, authenticated: false };
    case RECEIVE_TOKENS:
      const { accessToken = {}, idToken = {} } = action || {};
      return {
        ...state,
        accessToken: accessToken.value,
        idToken: idToken.value,
        authenticated: true,
      };
    default:
      return state;
  }
};
