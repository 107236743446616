const clientId = process.env.CLIENT_ID || '0oaee8isuh6OnSrOo4x6';
const issuer =
  process.env.ISSUER || 'https://dev-683076.okta.com/oauth2/default';
const OKTA_TESTING_DISABLEHTTPSCHECK =
  process.env.OKTA_TESTING_DISABLEHTTPSCHECK || false;
const [baseUrl] = issuer.split('/oauth2');

export default {
  oidc: {
    clientId,
    issuer,
    baseUrl,
    redirectUri: `${window.location.origin}/implicit/callback`,
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
    disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
  },
  resourceServer: {
    messagesUrl: 'http://localhost:8000/api/messages',
  },
};
