import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import getDisplayName from 'utilities/getDisplayName';
import { useSelector, useDispatch } from 'react-redux';
import { isAuthenticatedSelector } from 'selectors/authentication';
import { saveRedirectURI } from 'utilities/redirectURI';
import Loader from 'components/Loader';
import { recoverSession } from 'actions/authentication';

const withAuthentication = WrappedComponent => {
  const C = props => {
    const dispatch = useDispatch();
    const isAuthenticated = useSelector(isAuthenticatedSelector);
    useEffect(() => {
      saveRedirectURI();
      if (isAuthenticated === null) dispatch(recoverSession());
    }, [isAuthenticated, dispatch]);

    if (isAuthenticated) return <WrappedComponent />;
    if (isAuthenticated === null) {
      return <Loader variant="fullScreen" />;
    }
    return <Redirect to="/login" />;
  };
  C.displayName = `withAuthentication(${getDisplayName(
    WrappedComponent
  )})`;

  return C;
};

export default withAuthentication;
