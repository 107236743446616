import { takeEvery, put } from 'redux-saga/effects';
import {
  RECEIVE_STATE_AND_NONCE,
  RECOVER_SESSION,
  receiveTokens,
  sessionInactive,
} from 'actions/authentication';

export function* authenticate(auth, { state, nonce }) {
  const tokens = yield auth.getTokens({ state, nonce });
  yield put(receiveTokens(tokens));
  yield auth.storeTokens(tokens);
}

export function* retrieveSession(auth) {
  const sessionExists = yield auth.sessionExists();
  if (sessionExists) {
    const tokens = yield auth.retreiveStoredTokens();
    yield put(receiveTokens(tokens));
  } else {
    yield put(sessionInactive());
  }
}

export default function* authenticationSaga(auth) {
  yield takeEvery(RECEIVE_STATE_AND_NONCE, authenticate, auth);
  yield takeEvery(RECOVER_SESSION, retrieveSession, auth);
}
