import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LoginWidget from 'components/LoginWidget';

const useStyles = makeStyles({
  root: {
    height: '100vh',
    width: '100vw',
    overflow: 'hidden',
    display: 'grid',
    placeItems: 'center',
    background: 'radial-gradient(#40404b, #111118) rgba(34,34,40,0.94)',
  },
});

const Login = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <LoginWidget />
    </div>
  );
};

export default Login;
