import React from 'react';
import clsx from 'clsx';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  fullScreenRoot: {
    display: 'grid',
    height: '100vh',
    width: '100vw',
    position: 'fixed',
    placeItems: 'center',
  },
});

const Loader = ({ variant, classes: upstreamClasses = {} }) => {
  const classes = useStyles();
  if (variant === 'fullScreen')
    return (
      <div
        className={clsx(
          upstreamClasses.fullScreenRoot,
          classes.fullScreenRoot
        )}
      >
        <CircularProgress />
      </div>
    );
  return (
    <CircularProgress
      className={clsx(upstreamClasses.root, classes.root)}
    />
  );
};

export default Loader;
